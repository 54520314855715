import systemApi from "@/api/systemApi";
import request from '@/libs/request/index'
import {IAccountQuery, IAccount, IPassword} from "@/types/system/account.d.ts";
import {IResult} from "@/types/share.d.ts";

declare interface IUseAccount{
  getAgentAccount: (params: IAccountQuery) => Promise<any>;
  saveAgentAccount: (params: IAccount) => Promise<any>;
  editAgentAccount: (params: IAccount) => Promise<any>;
  delAgentAccount: (id: string | number) => Promise<any>;
  getAgentAccountDetail: (id: string | number) => Promise<any>;
  editAccountPassword: (params: IPassword) => Promise<any>;
}
function useAccount(): IUseAccount{
  //获取账号列表
  function getAgentAccount(params: IAccountQuery): Promise<IResult>{
    return  request.jsonPost(systemApi.agentAccountQuery, params)
  }
  //添加账号
  function saveAgentAccount(params: IAccount): Promise<IResult>{
    return request.jsonPost(systemApi.agentAccountSave, params)
  }
  //编辑账号
  function editAgentAccount(params: IAccount): Promise<IResult>{
    return request.put(systemApi.agentAccountEdit, params)
  }
  //删除账号
  function delAgentAccount(id: string | number): Promise<IResult>{
    return request.del(systemApi.agentAccountDelete + id)
  }
  //获取账号详情
  function getAgentAccountDetail(id: string | number): Promise<IResult>{
    return request.get(systemApi.agentAccountDetail + id)
  }
  //修改密码
  function editAccountPassword(params: IPassword): Promise<IResult>{
    return request.put(systemApi.agentAccountPassword, params)
  }
  return{
    getAgentAccount,
    saveAgentAccount,
    editAgentAccount,
    delAgentAccount,
    getAgentAccountDetail,
    editAccountPassword
  }
}

export {
  IUseAccount,
  useAccount
}
