import agentApi from "@/api/agentApi";
import request from '@/libs/request/index'
import { IStoreListQuery } from "@/types/store/storeList.d.ts";
import { IStoreStatisticQuery, IStoreStatisticDetailQuery } from "@/types/store/storeStatisti.dc";
import {IResult} from "@/types/share";

declare interface IUseAgentHotel{
  getAgentHotelList: (params: IStoreListQuery) => Promise<IResult>;
  getAgentHotelStatisticList: (params: IStoreStatisticQuery) => Promise<IResult>;
  getAgentHotelStatisticData: (prefix: string) => Promise<IResult>;
  getAgentStatisticsDetailList: (params: IStoreStatisticDetailQuery) => Promise<IResult>;
  getAgentHotelListAll: () => Promise<IResult>;
  exportAgentHotelList: (params: IStoreStatisticQuery) => Promise<IResult>;
  exportAgentHotelDetailList: (params: IStoreStatisticDetailQuery) => Promise<IResult>;
}
function useAgentHotel(): IUseAgentHotel{
  //获取代理门店列表
  function getAgentHotelList(params: IStoreListQuery): Promise<IResult>{
    return request.jsonPost(agentApi.agentHotelQuery, params)
  }

  //获取代理所有门店
  function getAgentHotelListAll(): Promise<IResult>{
    return request.get(agentApi.agentHotelQueryAll)
  }

  //获取代理门店统计列表
  function getAgentHotelStatisticList(params: IStoreStatisticQuery): Promise<IResult> {
    return request.jsonPost(agentApi.agentStatisticsQuery, params)
  }
  //获取酒店统计信息
  function getAgentHotelStatisticData(prefix: string): Promise<IResult>{
    return request.get(agentApi.agentStatisticsData+'?hotelPrefix='+prefix)
  }
  //获取酒店明细
  function getAgentStatisticsDetailList(params: IStoreStatisticDetailQuery): Promise<IResult>{
    return request.jsonPost(agentApi.agentStatisticsDetail, params)
  }

  //导出酒店统计数据
  function exportAgentHotelList(params: IStoreStatisticQuery): Promise<IResult>{
    return request.exportData(agentApi.agentStatisticsExport, params)
  }

  //导出酒店明细
  function exportAgentHotelDetailList(param: IStoreStatisticDetailQuery): Promise<IResult>{
    return request.exportData(agentApi.agentStatisticsExportDetail, param)
  }

  return{
    getAgentHotelList,
    getAgentHotelStatisticList,
    getAgentHotelStatisticData,
    getAgentStatisticsDetailList,
    getAgentHotelListAll,
    exportAgentHotelList,
    exportAgentHotelDetailList,
  }

}


export {
  IUseAgentHotel,
  useAgentHotel,
}
