import config from '@/config/index'
// const BASE_URL = process.env.NODE_ENV === 'production' ? config.roomAdminMs : ''
const BASE_URL =  config.roomAdminMs
export default {
  agentHomeRanking: BASE_URL + '/smarthotel-agent-ms/open/agent/home/ranking', //获取门店分成排行
  agentHomeStatistics: BASE_URL + '/smarthotel-agent-ms/open/agent/home/statistics', //获取首页统计信息和金额对比图
  agentHomeStatisticsDate: BASE_URL + '/smarthotel-agent-ms/open/agent/home/statistics/date', //获取首页统计信息和金额对比图
  agentHotelQuery: BASE_URL + '/smarthotel-agent-ms/open/agent/hotel/query', //分页查询代理门店列表
  agentHotelQueryAll: BASE_URL + '/smarthotel-agent-ms/open/agent/hotel', //查询所有代理门店列表
  agentStatisticsQuery: BASE_URL + '/smarthotel-agent-ms/open/agent/statistics/query', //获取代理门店统计信息
  agentStatisticsData: BASE_URL + '/smarthotel-agent-ms/open/agent/statistics/data', //获取酒店统计
  agentStatisticsDetail: BASE_URL + '/smarthotel-agent-ms/open/agent/statistics/detail', //获取酒店明细
  agentStatisticsExport: BASE_URL + '/smarthotel-agent-ms/open/agent/statistics/export', //导出门店分成统计信息
  agentStatisticsExportDetail: BASE_URL + '/smarthotel-agent-ms/open/agent/statistics/export/detail', //导出门店分成统计信息
}
